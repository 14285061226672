import React from 'react'
import { Grid, CardContent, Box, CardMedia, Typography } from '@mui/material'
import links from '../../../utils/constants/links'
import Button from './PurchaseCardButton'
import Card from './PurchaseCardComponent'
import CardHeader from './PurchaseCardHeader'

export default function BookACallCard({ showPostTrainingLink }) {
  const bookCallLink = showPostTrainingLink
    ? links.support.postTrainingSales
    : links.support.youcanbookme
  return (
    <Grid item xs={12} sm={4}>
      <Card backgroundColor="#f7f7f7">
        <Box sx={{ margin: '1rem 0' }}>
          <CardMedia
            image="/images/store/youcanbookme.jpg"
            sx={{
              height: '100px',
              width: '100px',
              borderRadius: '50%',
              margin: 'auto',
            }}
          />
          <CardHeader
            title="Not sure which plan is right for you?"
            color="#3c7e7d"
            align="center"
          />
          <CardContent>
            <Typography variant="body2" align="justify">
              Our Programs Consultants can help you find the right solution for your unique practice
              and approach.
            </Typography>
          </CardContent>
          <a
            style={{ width: '100%', display: 'flex', textDecorationLine: 'none' }}
            href={`${bookCallLink}`}
            target="_blank"
            rel="noreferrer"
          >
            <Button>Book a Call</Button>
          </a>
        </Box>
      </Card>
    </Grid>
  )
}
