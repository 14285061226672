/**
 * This component follows the mockup of UW-5352
 */

import {
  Container,
  Grid,
  CardContent,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  CardMedia,
  Typography,
  Stack,
} from '@mui/material'
import React, { useEffect } from 'react'
import { useOutletContext } from 'react-router'
import { get } from 'lodash'
import AddIcon from '@mui/icons-material/Add'
import { useDispatch, useSelector } from 'react-redux'
import useGetUser from 'utils/hooks/useGetUser'
import useGetShowWelcomeCall from 'utils/hooks/useGetShowWelcomeCall'
import ROLES from '../../utils/constants/roles'
import { includesSome } from '../../utils/includes'
import useGTM from 'utils/hooks/useGTM'
import { focusCardConfigs as cards } from './components/StoreFrontCardConfigs'
import BookACallCard from './components/BookACallCard'
import Card from './components/PurchaseCardComponent'
import CardHeader from './components/PurchaseCardHeader'
import PurchaseCard from './components/PurchaseCard'
import ContactAdministratorMessage from './components/ContactAdministratorMessage'
import { extendData } from 'store/modules/new-purchase'
import EMAILS from 'utils/constants/emails'

const MARGIN_LEFT = '0.5rem'

export default function FocusStoreFront() {
  const { setMainLayoutTitle } = useOutletContext()
  const { selectedUser } = useGetUser()
  const { hasFocusCertification, roles } = selectedUser
  const { showUserWelcomeCall } = useGetShowWelcomeCall()

  const isProvider = includesSome(roles, [...ROLES.PROVIDER_ROLES])
  const isBilling = roles.includes(ROLES.BILLING)
  const isProviderWithBilling = isProvider && isBilling

  const {
    hasOrgFocusCertificationCompleted,
    hasPaidFocusSubscription,
    hasPastDueFocusSubscription,
    hasPendingFocusSubscription,
    hasPaidSspSubscription,
    hasPastDueSspSubscription,
    hasPendingSspSubscription,
    hasAllCanceledFocusSubscription,
  } = useSelector((state) => get(state, 'organization', {}))

  const hasActiveFocusSubscription =
    hasPastDueFocusSubscription || hasPaidFocusSubscription || hasPendingFocusSubscription

  const hasActiveSspSubscription =
    hasPaidSspSubscription || hasPastDueSspSubscription || hasPendingSspSubscription

  const showTrainingPurchaseOption = isProviderWithBilling && !hasFocusCertification
  const showOnlyTrainingPurchaseOption = showTrainingPurchaseOption && hasActiveFocusSubscription
  const showSubscriptionPurchaseOptions = isBilling && !hasActiveFocusSubscription

  const { showNewSubscriptionPlan } = useSelector((state) => state.ff)

  const { pageView } = useGTM()
  useEffect(() => {
    const title = 'Purchase ILS'
    setMainLayoutTitle(title)

    // set the title on load
    pageView({
      pageUrl: window.document.location.href,
      pageTitle: 'Internal Purchase - Select items',
    })
    // eslint-disable-next-line
  })

  const subscriptionItems = [
    !hasFocusCertification && !hasActiveFocusSubscription && (
      <Typography variant="body2" color="#ce0093">
        <b>The Sounds of the Season Bundle</b> including Special Pricing on ILS Training (Plus Earn
        CEUs), Advanced Copy of Dr. Stephen Porges and Karen Onderko’s New Book, New Workbook and
        Exclusive Session with Deb Dana, and Two Months’ Access to Sounds True One
      </Typography>
    ),
    'Access to all 5 ILS Programs: Calming, Sensory & Motor, Concentration & Attention, Reading & Auditory Processing, and Optimal Performance',
    'Hardware Kit with Bone and Air Conduction Headphones & Amp',
    'Integration Kit with balance board, bean bags, balls and more',
    'Activity Library featuring instructional videos and printable PDFs for clients',
    'MyUnyte online platform for facilitating and monitoring client progress and administering assessments',
    'The Unyte Health App for convenient in-clinic and remote delivery',
    'Unmatched support both live and online from our Client Success team, a vast library of resources, monthly Q&As and more',
    '25 Client Licenses included with annual plan*',
    'Purchase additional Starter Kits for just $299!',
    !hasFocusCertification && !hasActiveFocusSubscription && (
      <Typography variant="body2" color="#ce0093">
        <b>Get $150 off MedBridge Education or Premium Annual Subscriptions***</b> to continue your
        education, earn CEUs and advance your career!
      </Typography>
    ),
  ].filter(Boolean) // Filter out falsey values

  // set change url to here
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(
      extendData({
        redirectUrl: '/store',
      })
    )
    // eslint-disable-next-line
  }, [])

  // show client success message when we have old ssp and no focus sub
  const showClientSuccessMessage =
    !showNewSubscriptionPlan && hasActiveSspSubscription && !hasActiveFocusSubscription

  return (
    <Container maxWidth="md" sx={{ margin: 0 }}>
      {/* Header */}
      <Grid container spacing={1} alignItems="stretch" justifyItems="space-between" mt={1}>
        <Grid sm={6}>
          <CardMedia
            image="/images/store/focus-store-front-header.png"
            sx={{
              height: '300px',
              maxWidth: '330px',
              margin: '0 0 0 15%',
            }}
          />
        </Grid>
        <Grid sm={6}>
          <Typography color="#3c7e7d" sx={{ fontSize: 30 }}>
            Start Improving Function & Skill Building Today with the Integrated Listening System
          </Typography>
          <br />
          <Typography>
            Experience the magic of brain and organization through multisensory input! The ILS
            provides all the tools and guided activities you need in one system. And, with
            subscription options that make it easy and economical to integrate this life-changing
            approach into your practice!
          </Typography>
        </Grid>
      </Grid>

      {!showClientSuccessMessage && (
        <Stack>
          {/* plans */}
          {isBilling && (
            <Grid container spacing={1} alignItems="stretch" justifyItems="space-between" mt={1}>
              {cards
                .filter(({ isActive }) =>
                  isActive({
                    hasFocusCertification,
                    hasOrgFocusCertificationCompleted,
                    hasActiveFocusSubscription,
                    hasAllCanceledFocusSubscription,
                  })
                )
                .map(
                  ({
                    title,
                    costPerMonth,
                    description,
                    trainingMessage,
                    subDescription,
                    link,
                    skus,
                    testName,
                  }) => (
                    <Grid item xs={12} sm={6} data-test={testName}>
                      <PurchaseCard
                        title={title}
                        costPerMonth={costPerMonth}
                        description={description}
                        trainingMessage={trainingMessage}
                        subDescription={subDescription({ hasActiveSspSubscription })}
                        link={link}
                        skus={skus}
                        marginLeft={MARGIN_LEFT}
                        data-test={testName}
                      />
                    </Grid>
                  )
                )}
            </Grid>
          )}
          {/* Subscription items */}
          <Grid container spacing={1} alignItems="stretch" sx={{ marginTop: '0rem' }}>
            {showSubscriptionPurchaseOptions && (
              <Grid item xs={12} sm={8} data-test="ils-subscription-details-card">
                <Card backgroundColor="#f7f7f7">
                  <CardHeader
                    title="Your ILS Subscription Includes:"
                    color="#3c7e7d"
                    sx={{ paddingBottom: 0 }}
                    data-test="ils-subscription-details-card"
                  />
                  <Box sx={{ margin: '0 0.5rem 0 0.5rem' }}>
                    <CardContent sx={{ padding: '0 0.5rem 0rem 0.5rem' }}>
                      <List>
                        {subscriptionItems.map((item) => (
                          <ListItem dense disableGutters>
                            <ListItemIcon sx={{ minWidth: '36px' }}>
                              <AddIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText
                              primary={item}
                              primaryTypographyProps={{ variant: 'body2' }}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </CardContent>
                    <CardContent
                      sx={{ padding: '0 0.5rem 0.5rem 0.5rem', margin: '0 0.5rem 0 0.5rem' }}
                    >
                      <Stack direction="column" spacing={1}>
                        <Typography variant="caption">
                          * Each Client License is accessible for 12 months from the start of
                          listening. Add additional Client Licenses anytime for $25.
                        </Typography>
                        <Typography variant="caption">
                          ** All subscription plans automatically renew.
                        </Typography>
                        {!hasFocusCertification && !hasActiveFocusSubscription && (
                          <Typography variant="caption" color="#ce0093">
                            *** MedBridge: Bonus is applicable only to new MedBridge subscribers.
                          </Typography>
                        )}
                        {!showNewSubscriptionPlan && (
                          <Typography variant="caption" style={{ fontStyle: 'italic' }}>
                            Please{' '}
                            <a
                              href="https://connect.unyte.com/meetings/unyte/success-mu"
                              target="_blank"
                              rel="noreferrer"
                              style={{ textDecoration: 'none' }}
                            >
                              Book a call
                            </a>{' '}
                            with our Client Success team or contact{' '}
                            <a
                              href="mailto:success@unyte.com"
                              target="_blank"
                              rel="noreferrer"
                              style={{ textDecoration: 'none' }}
                            >
                              success@unyte.com
                            </a>{' '}
                            to discuss options and discounts.
                          </Typography>
                        )}
                      </Stack>
                    </CardContent>
                  </Box>
                </Card>
              </Grid>
            )}

            <Grid
              item
              sx={{ display: 'flex', flexDirection: 'column' }}
              xs={12}
              sm={4}
              direction="column"
            >
              {showTrainingPurchaseOption && (
                /* Purchase ILS certification */
                <Grid
                  sx={{ margin: '0 0 5px 0' }}
                  item
                  md={4}
                  xs={12}
                  sm={4}
                  data-test="ils-certification-card"
                >
                  <PurchaseCard
                    title={'Foundational Integrated Listening System Training'}
                    oneTimeCost={199}
                    description={
                      <Typography>
                        Get ILS online training! Purchase your subscription later.
                      </Typography>
                    }
                    link={'/purchase/confirm'}
                    skus={['focusCertification']}
                    marginLeft={MARGIN_LEFT}
                    data-test="ils-certification-card"
                  />
                  {showOnlyTrainingPurchaseOption && (
                    <Typography variant="caption">
                      Please note: an active ILS subscription is required to deliver the ILS program
                    </Typography>
                  )}
                </Grid>
              )}
              {/* Book a call */}
              {showSubscriptionPurchaseOptions && (
                <BookACallCard showPostTrainingLink={showUserWelcomeCall} />
              )}
            </Grid>
          </Grid>

          {isProvider && !isBilling && !(hasFocusCertification && hasActiveFocusSubscription) && (
            <ContactAdministratorMessage />
          )}
        </Stack>
      )}
      {showClientSuccessMessage && (
        <Typography>
          Please{' '}
          <a
            className="text-link text-semibold"
            href="https://connect.unyte.com/meetings/unyte/success-mu"
            target="_blank"
            rel="noreferrer"
          >
            Book
          </a>{' '}
          a call with our Client Success team or contact{' '}
          <a className="text-link text-semibold" href={`mailto:${EMAILS.supportEmail}`}>
            {EMAILS.supportEmail}
          </a>{' '}
          to discuss options and discounts.
        </Typography>
      )}
    </Container>
  )
}
