import React from 'react'
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles'

export const TEXTFIELD_VARIANT = process.env.REACT_APP_TEXTFIELD_VARIANT ?? 'standard'

export const themeDefinition = {
  palette: {
    primary: {
      main: '#008080',
      immediate: '#008080',
      dark: '#008080',
    },
    info: {
      main: 'rgba(0, 0, 0, 0.87)',
      immediate: 'rgba(0, 0, 0, 0.5)',
      dark: 'rgba(0, 0, 0, 0.5)',
    },
    dark: { main: '#008080' },
    unyteSecondary: { main: '#ce0093' },
    secondary: {
      main: '#f50057',
    },
    favorite: {
      main: '#F472B6',
    },
    // this is more for assessment buttons (all black?)
    text: {
      main: 'rgba(0, 0, 0, 0.87)',
    },
    gray: {
      main: '#2d3748',
      light: '#f0f7f2',
    },
    yellow: {
      light: '#F7F0DB',
      dark: '#D7B54B',
    },
    mint: {
      main: '#eef7f3',
      dark: '#acd8c4',
    },
    clientConnectionPrimary: {
      main: '#3784AD',
      contrastText: '#fff',
    },
    greyedOut: {
      main: '#b0b0b0', // Darker grey
      contrastText: '#f5f5f5', // Off-white
    },
    pink: {
      main: '#fD4379',
      dark: '#d43790',
      contrastText: 'white',
    },
  },
  // font sizes are from GOOGLE DOCS
  typography: {
    h1: { color: 'rgba(0, 0, 0, 0.87)', fontSize: '4.5rem' /* 72px */, lineHeight: '1' },
    h2: { color: 'rgba(0, 0, 0, 0.87)', fontSize: '2.25rem' /* 72px */, lineHeight: '2.5rem' },
    h3: { color: 'rgba(0,0,0,0.87)' },
    h4: { color: 'rgba(0,0,0,0.87)', fontSize: '2rem' }, // used for headers (NOT CARDS)
    h5: { color: 'rgba(0,0,0,0.87)' }, // fontSize : '1.5rem' - CARDS
    h6: { color: 'rgba(0,0,0,0.87)', fontSize: '0.9em' }, // this is used for Table headers like filters
    p: { color: 'rgba(0,0,0,0.87)' },
    body2: { color: 'rgba(0,0,0,0.87)' },
    body1: { color: 'rgba(0,0,0,0.87)' },
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          // This will apply the color to all table cells
          color: 'rgba(0,0,0,0.87)',
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: TEXTFIELD_VARIANT,
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: TEXTFIELD_VARIANT,
        margin: 'dense',
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiDialogTitle: {
      defaultProps: {
        variant: 'h5',
      },
    },
    MuiChip: {
      styleOverrides: {
        sizeSmall: {
          height: '20px',
        },
      },
    },
  },
}

const theme = createTheme(themeDefinition)

export default function MuiThemeProvider({ children }) {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  )
}
