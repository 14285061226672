export const SET_MENU_STATE = 'APP/SET_MENU_STATE'
export const SET_ERRORS = 'APP/SET_ERRORS'
export const SET_INRO_VIDEO_STATE = 'APP/SET_INRO_VIDEO_STATE'
export const SET_PRODUCT = 'APP/SET_PRODUCT'
export const SET_BANNER_STATE = 'APP/SET_BANNER_STATE'

const initialState: State = {
  showMenu: false,
  errors: [],
  showIntroVideo: false,
  product: '',
}

type State = {
  showMenu: boolean
  errors: Array<string>
  showIntroVideo: boolean
  product: string
}

export default (state: State = initialState, action: any) => {
  switch (action.type) {
    case SET_MENU_STATE:
      return {
        ...state,
        showMenu: action.payload,
      }
    case SET_ERRORS:
      return {
        ...state,
        errors: action.payload,
      }
    case SET_INRO_VIDEO_STATE:
      return {
        ...state,
        showIntroVideo: action.payload,
      }
    case SET_PRODUCT:
      return {
        ...state,
        product: action.payload,
      }
    case SET_BANNER_STATE:
      return {
        ...state,
      }
    default:
      return state
  }
}

export function setMenuState(payload) {
  return {
    type: SET_MENU_STATE,
    payload,
  }
}

export function setNotifications(payload) {
  return {
    type: SET_ERRORS,
    payload,
  }
}

export function setIntroVideoState(payload) {
  return {
    type: SET_INRO_VIDEO_STATE,
    payload,
  }
}

export function setProduct(payload) {
  return {
    type: SET_PRODUCT,
    payload,
  }
}
