export const SSP_CERTIFICATION_SKUS = ['sspCertification']
export const RRP_CERTIFICATION_SKUS = ['rrpCertification']
export const VOICE_PRO_CERTIFICATION_SKUS = ['voiceProCertification']
export const FOCUS_CERTIFICATION_SKUS = ['focusCertification']
export const FOCUS_SYSTEM_SKUS = ['FOCUS-STRT']
export const FOCUS_TEMPORARY = [
  'focusTemporary',
  'focusUnyteEmployee',
  'focusComplimentary',
  'focusCustom',
  'focusAustralia',
]

export const FOCUS_SUBSCRIPTIONS_SKUS = [
  'focusYearToYear_Yearly',
  'focusMonthToMonth_Yearly_Bundle',
  'focusMonthToMonth_Yearly',
]

export const FOCUS_SUBSCRIPTIONS_BUNDLE_SKUS = ['focusMonthToMonth_Yearly_Bundle']
export const ALL_FOCUS_SUBSCRIPTIONS_SKUS = [...FOCUS_TEMPORARY, ...FOCUS_SUBSCRIPTIONS_SKUS]

export const PROMO_SKUS = ['2024ActionTraumaVirtual', 'SSPPromoQ42024', 'ILSPromoQ42024']

// Temporary placeholder for sspTemporary related SKUS
// https://unytehealth.atlassian.net/browse/UW-3144
export const TEMPORARY_SSP_SUBSCRIPTION_SKUS = [
  'sspUnyteEmployee',
  'sspAustralia',
  'sspCheque',
  'sspWireTransfer',
  'sspPayPal',
  'sspComplimentary',
  'sspBrainHarmony',
  'sspCustom',
]

// Temporary placeholder for rrpTemporary related SKUS
export const TEMPORARY_RRP_SUBSCRIPTION_SKUS = [
  'rrpUnyteEmployee',
  'rrpAustralia',
  'rrpCheque',
  'rrpWireTransfer',
  'rrpPayPal',
  'rrpComplimentary',
  'rrpCustom',
]

export const SSP_MONTHLY_SUBSCRIPTION_SKUS = [
  'sspMonthToMonth_3Monthly',
  'sspMonthToMonth_3Monthly_Bundle',
  'sspMonthToMonth_Yearly',
  'sspMonthToMonth_Yearly_Bundle',
  'sspMonthToMonth_Monthly', // purchased via Stripe dashboard only
]

export const SSP_SUBSCRIPTION_SKUS = [...SSP_MONTHLY_SUBSCRIPTION_SKUS, 'sspYearToYear_Yearly']
export const ALL_SSP_SUBSCRIPTION_SKUS = [
  ...TEMPORARY_SSP_SUBSCRIPTION_SKUS,
  ...SSP_SUBSCRIPTION_SKUS,
]

// RRP SKUS
export const RRP_MONTHLY_SUBSCRIPTION_SKUS = [
  'rrpMonthToMonth_3Monthly',
  'rrpMonthToMonth_3Monthly_Bundle',
  'rrpMonthToMonth_Yearly',
  'rrpMonthToMonth_Yearly_Bundle',
  'rrpMonthToMonth_Monthly', // purchased via Stripe dashboard only
]

export const RRP_SUBSCRIPTION_SKUS = [...RRP_MONTHLY_SUBSCRIPTION_SKUS, 'rrpYearToYear_Yearly']
export const ALL_RRP_SUBSCRIPTION_SKUS = [
  ...TEMPORARY_RRP_SUBSCRIPTION_SKUS,
  ...RRP_SUBSCRIPTION_SKUS,
]

// ALL [category] SKUS
export const ALL_CERTIFICATION_SKUS = [
  ...SSP_CERTIFICATION_SKUS,
  ...RRP_CERTIFICATION_SKUS,
  ...FOCUS_CERTIFICATION_SKUS,
  ...VOICE_PRO_CERTIFICATION_SKUS,
]
export const ALL_FOCUS_SKUS = [
  ...FOCUS_CERTIFICATION_SKUS,
  ...FOCUS_SYSTEM_SKUS,
  ...ALL_FOCUS_SUBSCRIPTIONS_SKUS,
]
export const ALL_VOICE_PRO_SKUS = [...VOICE_PRO_CERTIFICATION_SKUS]
export const ALL_SSP_SKUS = [...SSP_CERTIFICATION_SKUS, ...SSP_SUBSCRIPTION_SKUS]
export const ALL_RRP_SKUS = [...RRP_CERTIFICATION_SKUS, ...RRP_SUBSCRIPTION_SKUS]
export const ALL_VALID_SKUS = [
  ...PROMO_SKUS,
  ...ALL_FOCUS_SKUS,
  ...ALL_VOICE_PRO_SKUS,
  ...SSP_CERTIFICATION_SKUS,
  ...SSP_SUBSCRIPTION_SKUS,
  ...RRP_CERTIFICATION_SKUS,
  ...RRP_SUBSCRIPTION_SKUS,
]

// DISCOUNTS
export const BUNDLE_PROMO_CODES = [
  {
    couponName: 'SSP Sounds Of The Season Discount',
    requiredSkus: ['sspCertification', 'SSPPromoQ42024'],
    discountAmount: 149,
    key: 'sspSoundsOfTheSeasonDiscount',
  },
  {
    couponName: 'ILS Sounds Of The Season Discount',
    requiredSkus: ['focusCertification', 'ILSPromoQ42024'],
    discountAmount: 49,
    key: 'ilsSoundsOfTheSeasonDiscount',
  },
  {
    couponName: 'SSP Bundle Discount',
    requiredSkus: ['sspCertification', 'sspYearToYear_Yearly'],
    discountAmount: 100,
    key: 'sspYearlyBundleDiscount',
  },
  {
    couponName: 'SSP Bundle Discount',
    requiredSkus: ['sspCertification', 'sspMonthToMonth_Yearly_Bundle'],
    discountAmount: 100,
    key: 'sspYearlyBundleDiscount',
  },
  {
    couponName: 'Training Bundle Discount',
    requiredSkus: ['sspCertification', 'focusCertification'],
    discountAmount: 99,
    key: 'trainingBundleDiscount',
  },
  {
    couponName: 'FREE ILS Starter Pack',
    requiredSkus: ['FOCUS-STRT', 'focusYearToYear_Yearly'],
    discountAmount: 299,
    key: 'focusBundleDiscount',
  },
  {
    couponName: 'FREE ILS Starter Pack',
    requiredSkus: ['FOCUS-STRT', 'focusMonthToMonth_Yearly_Bundle'],
    discountAmount: 299,
    key: 'focusBundleDiscount',
  },
  {
    couponName: 'ILS Bundle Discount',
    requiredSkus: ['focusCertification', 'focusYearToYear_Yearly'],
    discountAmount: 50,
    key: 'focusBundleDiscount',
  },
  {
    couponName: 'ILS Bundle Discount',
    requiredSkus: ['focusCertification', 'focusMonthToMonth_Yearly'],
    discountAmount: 50,
    key: 'focusBundleDiscount',
  },
  {
    couponName: 'ILS Bundle Discount',
    requiredSkus: ['focusCertification', 'focusMonthToMonth_Yearly_Bundle'],
    discountAmount: 50,
    key: 'focusBundleDiscount',
  },
]

const SSP_ILS_INTERNAL_PURCHASE_PROMOS = [
  {
    couponName: 'SSP Sounds Of The Season Discount',
    requiredSkus: ['sspCertification', 'SSPPromoQ42024'],
    discountAmount: 149,
    key: 'sspSoundsOfTheSeasonDiscount',
  },
  {
    couponName: 'ILS Sounds Of The Season Discount',
    requiredSkus: ['focusCertification', 'ILSPromoQ42024'],
    discountAmount: 49,
    key: 'ilsSoundsOfTheSeasonDiscount',
  },
  {
    couponName: 'SSP Bundle Discount',
    requiredSkus: ['sspCertification', 'sspYearToYear_Yearly'],
    applyToSku: 'sspCertification',
    discountAmount: 100,
    key: 'sspYearlyBundleDiscount_Internal',
  },
  {
    couponName: 'SSP Bundle Discount',
    requiredSkus: ['sspCertification', 'sspMonthToMonth_Yearly_Bundle'],
    applyToSku: 'sspCertification',
    discountAmount: 100,
    key: 'sspYearlyBundleDiscount_Internal',
  },
  {
    couponName: 'ILS subscriber discount',
    requiredSkus: ['sspYearToYear_Yearly'],
    requiredAnyExistingSkus: ['focusYearToYear_Yearly', 'focusMonthToMonth_Yearly'],
    applyToSku: 'sspYearToYear_Yearly',
    discountAmount: 500,
    key: 'sspExistingFocusDiscount_Internal',
  },
  {
    couponName: 'ILS subscriber discount',
    requiredSkus: ['sspMonthToMonth_Yearly'],
    requiredAnyExistingSkus: ['focusYearToYear_Yearly', 'focusMonthToMonth_Yearly'],
    shouldApplyToSubscription: true,
    applyToSku: 'sspMonthToMonth_Yearly',
    discountAmount: 42,
    key: 'sspExistingFocusDiscount_Internal',
  },
  {
    couponName: 'ILS subscriber discount',
    requiredSkus: ['sspMonthToMonth_Yearly_Bundle'],
    requiredAnyExistingSkus: ['focusYearToYear_Yearly', 'focusMonthToMonth_Yearly'],
    shouldApplyToSubscription: true,
    applyToSku: 'sspMonthToMonth_Yearly_Bundle',
    shouldExcludeDiscountFromCartItems: true,
    discountAmount: 42,
    key: 'sspExistingFocusDiscount_Internal',
  },
  {
    couponName: 'ILS Bundle Discount',
    requiredSkus: ['focusCertification', 'focusYearToYear_Yearly'],
    applyToSku: 'focusCertification',
    discountAmount: 50,
    key: 'focusYearlyBundleDiscount_Internal',
  },
  {
    couponName: 'ILS Bundle Discount',
    requiredSkus: ['focusCertification', 'focusMonthToMonth_Yearly_Bundle'],
    applyToSku: 'focusCertification',
    discountAmount: 50,
    key: 'focusYearlyBundleDiscount_Internal',
  },
  {
    couponName: 'SSP subscriber discount',
    requiredSkus: ['focusYearToYear_Yearly'],
    requiredAnyExistingSkus: ['sspYearToYear_Yearly', 'sspMonthToMonth_Yearly'],
    applyToSku: 'focusYearToYear_Yearly',
    discountAmount: 500,
    key: 'focusExistingFocusDiscount_Internal',
  },
  {
    couponName: 'SSP subscriber discount',
    requiredSkus: ['focusMonthToMonth_Yearly'],
    requiredAnyExistingSkus: ['sspYearToYear_Yearly', 'sspMonthToMonth_Yearly'],
    shouldApplyToSubscription: true,
    applyToSku: 'focusMonthToMonth_Yearly',
    discountAmount: 42,
    key: 'focusExistingFocusDiscount_Internal',
  },
  {
    couponName: 'SSP subscriber discount',
    requiredSkus: ['focusMonthToMonth_Yearly_Bundle'],
    requiredAnyExistingSkus: ['sspYearToYear_Yearly', 'sspMonthToMonth_Yearly'],
    shouldApplyToSubscription: true,
    applyToSku: 'focusMonthToMonth_Yearly_Bundle',
    shouldExcludeDiscountFromCartItems: true,
    discountAmount: 42,
    key: 'focusExistingFocusDiscount_Internal',
  },
  {
    couponName: 'FREE ILS Starter Pack',
    requiredSkus: ['focusMonthToMonth_Yearly_Bundle', 'FOCUS-STRT'],
    applyToSku: 'FOCUS-STRT',
    discountAmount: 299,
    key: 'focusBundleDiscount',
  },
  {
    couponName: 'FREE ILS Starter Pack',
    requiredSkus: ['focusMonthToMonth_Yearly', 'FOCUS-STRT'],
    applyToSku: 'FOCUS-STRT',
    discountAmount: 299,
    key: 'focusBundleDiscount',
  },
  {
    couponName: 'FREE ILS Starter Pack',
    requiredSkus: ['focusYearToYear_Yearly', 'FOCUS-STRT'],
    applyToSku: 'FOCUS-STRT',
    discountAmount: 299,
    key: 'focusBundleDiscount',
  },
]

/* RRP Multiple product Discounts */
export const RRP_INTERNAL_PURCHASE_PROMOS = [
  {
    couponName: 'yearly/monthly SSP or ILS subscriber discount',
    requiredSkus: ['rrpYearToYear_Yearly'],
    requiredAnyExistingSkus: [
      'sspYearToYear_Yearly',
      'sspMonthToMonth_Yearly',
      'focusYearToYear_Yearly',
      'focusMonthToMonth_Yearly',
    ],
    applyToSku: 'rrpYearToYear_Yearly',
    discountAmount: 799,
    key: 'rrp2ndSubDiscount',
  },
  {
    couponName: 'yearly/monthly SSP or ILS subscriber discount',
    requiredSkus: ['rrpMonthToMonth_3Monthly'],
    requiredAnyExistingSkus: [
      'sspYearToYear_Yearly',
      'sspMonthToMonth_Yearly',
      'focusYearToYear_Yearly',
      'focusMonthToMonth_Yearly',
    ],
    shouldApplyToSubscription: true,
    applyToSku: 'rrpMonthToMonth_3Monthly',
    discountAmount: 89,
    key: 'rrp2ndSubDiscount',
  },
  {
    couponName: 'yearly/monthly SSP or ILS subscriber discount',
    requiredSkus: ['rrpMonthToMonth_3Monthly_Bundle'],
    requiredAnyExistingSkus: [
      'sspYearToYear_Yearly',
      'sspMonthToMonth_Yearly',
      'focusYearToYear_Yearly',
      'focusMonthToMonth_Yearly',
    ],
    shouldApplyToSubscription: true,
    applyToSku: 'rrpMonthToMonth_3Monthly_Bundle',
    shouldExcludeDiscountFromCartItems: true,
    discountAmount: 89,
    key: 'rrp2ndSubDiscount',
  },
  // {
  //   couponName: '3monthly SSP subscriber discount',
  //   requiredSkus: ['rrpYearToYear_Yearly'],
  //   requiredAnyExistingSkus: ['sspMonthToMonth_3Monthly'],
  //   shouldApplyToSubscription: true,
  //   applyToSku: 'rrpYearToYear_yearly',
  //   discountAmount: 89,
  //   key: 'rrp2ndSubDiscount',
  // },
  {
    couponName: '3monthly SSP subscriber discount',
    requiredSkus: ['rrpMonthToMonth_Yearly'],
    requiredAnyExistingSkus: ['sspMonthToMonth_3Monthly'],
    shouldApplyToSubscription: true,
    applyToSku: 'rrpMonthToMonth_Yearly',
    discountAmount: 89,
    key: 'rrp2ndSubDiscount',
  },
  {
    couponName: '3monthly SSP subscriber discount',
    requiredSkus: ['rrpMonthToMonth_Yearly_Bundle'],
    requiredAnyExistingSkus: ['sspMonthToMonth_3Monthly'],
    shouldApplyToSubscription: true,
    applyToSku: 'rrpMonthToMonth_Yearly_Bundle',
    shouldExcludeDiscountFromCartItems: true,
    discountAmount: 89,
    key: 'rrp2ndSubDiscount',
  },
  {
    couponName: '3monthly SSP subscriber discount',
    requiredSkus: ['rrpMonthToMonth_3Monthly'],
    requiredAnyExistingSkus: ['sspMonthToMonth_3Monthly'],
    shouldApplyToSubscription: true,
    applyToSku: 'rrpMonthToMonth_Yearly',
    discountAmount: 89,
    key: 'rrp2ndSubDiscount',
  },
  {
    couponName: '3monthly SSP subscriber discount',
    requiredSkus: ['rrpMonthToMonth_3Monthly_Bundle'],
    requiredAnyExistingSkus: ['sspMonthToMonth_3Monthly'],
    shouldApplyToSubscription: true,
    applyToSku: 'rrpMonthToMonth_Yearly_Bundle',
    shouldExcludeDiscountFromCartItems: true,
    discountAmount: 89,
    key: 'rrp2ndSubDiscount',
  },
  {
    couponName: 'yearly/monthly SSP or ILS subscriber discount',
    requiredSkus: ['rrpMonthToMonth_Yearly'],
    requiredAnyExistingSkus: [
      'sspYearToYear_Yearly',
      'sspMonthToMonth_Yearly',
      'focusYearToYear_Yearly',
      'focusMonthToMonth_Yearly',
    ],
    shouldApplyToSubscription: true,
    applyToSku: 'rrpMonthToMonth_Yearly',
    discountAmount: 79,
    key: 'rrp2ndSubDiscount',
  },
  {
    couponName: 'yearly/monthly SSP or ILS subscriber discount',
    requiredSkus: ['rrpMonthToMonth_Yearly_Bundle'],
    requiredAnyExistingSkus: [
      'sspYearToYear_Yearly',
      'sspMonthToMonth_Yearly',
      'focusYearToYear_Yearly',
      'focusMonthToMonth_Yearly',
    ],
    shouldApplyToSubscription: true,
    applyToSku: 'rrpMonthToMonth_Yearly_Bundle',
    shouldExcludeDiscountFromCartItems: true,
    discountAmount: 79,
    key: 'rrp2ndSubDiscount',
  },
]

export const INTERNAL_PURCHASE_PROMOS = [
  ...SSP_ILS_INTERNAL_PURCHASE_PROMOS,
  ...RRP_INTERNAL_PURCHASE_PROMOS,
]

/**
 * items here will be added to cart
 *   eg `{ sspUpfrontBundlePromoFeb2021: ['polyvagalFlipChart', 'psychotherapyNetwork'] }`
 */
export const BUNDLE_PROMO_SKUS = {
  '2024ActionTraumaVirtual': ['2024ActionTraumaVirtual'],
  SSPPromoQ42024: [
    'SafeAndSound',
    'NervousSystemWorkbook',
    'VirtualGroupCoachingSession',
    'TwoMonthsComplimentaryAccess',
  ],
  ILSPromoQ42024: [
    'SafeAndSound',
    'NervousSystemWorkbook',
    'VirtualGroupCoachingSession',
    'TwoMonthsComplimentaryAccess',
    'MedBridgeDiscount',
  ],
}

export const INVALID_SKUS_COMBO = {}

/**
 * sku: stripe name
 * type: differentiate between certification and subscription
 *   - this field does not determine the frequency when a user is charged
 *     - an annual bundle is charged upfront and is a subscription
 */

const PROMO_PRODUCTS = {
  /*
  Keeping below as an example of a removable line item, and adding shipping prompt
  SSPPromoQ42021: {
    sku: 'SSPPromoQ42021',
    description: '', // description text goes under cartTitle for Promos. Defaults to "One Time Payment"
    ...
  },
  SSPPromoQ42021Bundle: {
    sku: 'SSPPromoQ42021Bundle',
    isRemovable: true,
    skuToRemove: 'SSPPromoQ42021',
    isPromoWithShipping: true, // Needed to show shipping screen
    weight: 0, // Needed to avoid shipstation error
    ...
  },
  */
  '2024ActionTraumaVirtual': {
    sku: '2024ActionTraumaVirtual',
    type: 'promo',
    price: 0,
    immediateChargePrice: 0,
    description:
      'Virtual ticket to Action Trauma Belfast on June 15-18 + 1 year Action Trauma Network membership.',
    frequency: 'once',
    cartTitle: 'Free 2024 Action Trauma Summit Virtual Ticket',
    gtmVariant: 'Physical',
    productCategories: ['ssp', 'focus'],
    accessoryCategories: [],
    weight: 0,
  },
  SSPPromoQ42024: {
    sku: 'SSPPromoQ42024',
    type: 'promo',
    price: 0,
    immediateChargePrice: 0,
    description: '',
    frequency: 'once',
    gtmCategory: 'Physical',
    cartTitle: 'SSP Promo: Sounds of the Season',
    gtmVariant: 'bundle',
    productCategories: ['ssp'],
    accessoryCategories: [],
    weight: 0,
  },
  ILSPromoQ42024: {
    sku: 'ILSPromoQ42024',
    type: 'promo',
    price: 0,
    immediateChargePrice: 0,
    description: '',
    frequency: 'once',
    gtmCategory: 'Physical',
    cartTitle: 'ILS Promo: Sounds of the Season',
    gtmVariant: 'bundle',
    productCategories: ['focus'],
    accessoryCategories: [],
    weight: 0,
  },
  SafeAndSound: {
    sku: 'SafeAndSound',
    type: 'promo',
    price: 0,
    immediateChargePrice: 0,
    description: '(value of $19.99)',
    cartTitle:
      'Bonus: Exclusive Audio Excerpt and Advanced Digital Copy of Safe and Sound: A Polyvagal Approach for Connection, Change, and Healing',
    gtmVariant: 'bundle',
    productCategories: ['ssp', 'focus'],
    accessoryCategories: [],
    weight: 0,
  },
  NervousSystemWorkbook: {
    sku: 'NervousSystemWorkbook',
    type: 'promo',
    price: 0,
    immediateChargePrice: 0,
    description:
      'Fulfilled by Sounds True; shipping additional for physical copy (value of $22.99)',
    cartTitle: 'Bonus: Free Physical or Digital Copy of the Nervous System Workbook',
    gtmVariant: 'bundle',
    productCategories: ['ssp', 'focus'],
    accessoryCategories: [],
    weight: 0,
  },
  VirtualGroupCoachingSession: {
    sku: 'VirtualGroupCoachingSession',
    type: 'promo',
    price: 0,
    immediateChargePrice: 0,
    description: '(value of $250)',
    cartTitle:
      'Bonus: Virtual Group Coaching Session on the Nervous System Workbook with Author Deb Dana',
    gtmVariant: 'bundle',
    productCategories: ['ssp', 'focus'],
    accessoryCategories: [],
    weight: 0,
  },
  TwoMonthsComplimentaryAccess: {
    sku: 'TwoMonthsComplimentaryAccess',
    type: 'promo',
    price: 0,
    immediateChargePrice: 0,
    description: '(value of $39.98)',
    cartTitle: 'Bonus: 2 Months Complimentary Access to Sounds True One',
    gtmVariant: 'bundle',
    productCategories: ['ssp', 'focus'],
    accessoryCategories: [],
    weight: 0,
  },
  MedBridgeDiscount: {
    sku: 'MedBridgeDiscount',
    type: 'promo',
    price: 0,
    immediateChargePrice: 0,
    description: 'Earn CEUs and advance your career',
    cartTitle: '$150 off of MedBridge Education or Premium Annual Subscriptions',
    gtmVariant: 'bundle',
    productCategories: ['focus'],
    accessoryCategories: [],
    weight: 0,
  },
}

const SSP_PRODUCTS = {
  sspCertification: {
    sku: 'sspCertification',
    type: 'digitalProduct',
    price: 349,
    immediateChargePrice: 349,
    description: 'SSP Training Basic',
    frequency: 'once',
    cartTitle: 'Foundational SSP Training',
    gtmCategory: 'Training',
    productCategories: ['ssp'],
    accessoryCategories: [],
    weight: 0,
  },
  sspMonthToMonth_3Monthly: {
    sku: 'sspMonthToMonth_3Monthly',
    type: 'subscription',
    price: 169,
    immediateChargePrice: 169,
    description: 'Paid monthly, 3 month term, auto renewal',
    frequency: 'month',
    cartTitle: 'SSP Monthly, 3 Month Subscription',
    gtmCategory: 'SSP',
    gtmVariant: 'monthly',
    productCategories: ['ssp'],
    accessoryCategories: ['ssp'],
    weight: 0,
  },
  sspMonthToMonth_3Monthly_Bundle: {
    sku: 'sspMonthToMonth_3Monthly_Bundle',
    type: 'subscription',
    price: 169,
    immediateChargePrice: 0,
    description: 'Paid monthly, 3 month term, auto renewal',
    frequency: 'month',
    cartTitle: 'SSP Monthly, 3 Month Subscription',
    gtmCategory: 'SSP',
    gtmVariant: 'monthly',
    productCategories: ['ssp'],
    accessoryCategories: ['ssp'],
    weight: 0,
    showDisclaimer: true,
  },
  sspMonthToMonth_Yearly: {
    sku: 'sspMonthToMonth_Yearly',
    type: 'subscription',
    price: 139,
    immediateChargePrice: 139,
    description: 'Paid monthly, 1 year term, auto renewal',
    frequency: 'month',
    cartTitle: 'SSP Monthly, Annual Subscription',
    gtmCategory: 'SSP',
    gtmVariant: 'monthly',
    productCategories: ['ssp'],
    accessoryCategories: ['ssp'],
    weight: 0,
  },
  sspMonthToMonth_Yearly_Bundle: {
    sku: 'sspMonthToMonth_Yearly_Bundle',
    type: 'subscription',
    price: 139,
    immediateChargePrice: 0,
    description: 'Paid monthly, 1 year term, auto renewal',
    frequency: 'month',
    cartTitle: 'SSP Monthly, Annual Subscription',
    gtmCategory: 'SSP',
    gtmVariant: 'monthly',
    productCategories: ['ssp'],
    accessoryCategories: ['ssp'],
    weight: 0,
    showDisclaimer: true,
  },
  sspYearToYear_Yearly: {
    sku: 'sspYearToYear_Yearly',
    type: 'subscription',
    price: 1499,
    immediateChargePrice: 1499,
    description: '1 year term, auto renewal',
    frequency: 'year',
    gtmCategory: 'SSP',
    cartTitle: 'SSP Annual Subscription',
    gtmVariant: 'yearly',
    productCategories: ['ssp'],
    accessoryCategories: ['ssp'],
    weight: 0,
  },
}

const RRP_PRODUCTS = {
  rrpCertification: {
    sku: 'rrpCertification',
    type: 'digitalProduct',
    price: 0,
    immediateChargePrice: 0,
    description: 'RRP Preliminary Training',
    frequency: 'once',
    cartTitle: 'RRP Preliminary Training',
    gtmCategory: 'Training',
    productCategories: ['rrp'],
    accessoryCategories: [],
    weight: 0,
  },
  rrpMonthToMonth_3Monthly: {
    sku: 'rrpMonthToMonth_3Monthly',
    type: 'subscription',
    price: 169,
    immediateChargePrice: 169,
    description: 'Paid monthly, 3 month term, auto renewal',
    frequency: 'month',
    cartTitle: 'RRP Monthly, 3 Month Subscription',
    gtmCategory: 'RRP',
    gtmVariant: 'monthly',
    productCategories: ['rrp'],
    accessoryCategories: ['rrp'],
    weight: 0,
  },
  rrpMonthToMonth_3Monthly_Bundle: {
    sku: 'rrpMonthToMonth_3Monthly_Bundle',
    type: 'subscription',
    price: 169,
    immediateChargePrice: 0,
    description: 'Paid monthly, 3 month term, auto renewal',
    frequency: 'month',
    cartTitle: 'RRP Monthly, 3 Month Subscription',
    gtmCategory: 'RRP',
    gtmVariant: 'monthly',
    productCategories: ['rrp'],
    accessoryCategories: ['rrp'],
    weight: 0,
    showDisclaimer: true,
  },
  rrpMonthToMonth_Yearly: {
    sku: 'rrpMonthToMonth_Yearly',
    type: 'subscription',
    price: 139,
    immediateChargePrice: 139,
    description: 'Paid monthly, 1 year term, auto renewal',
    frequency: 'month',
    cartTitle: 'RRP Monthly, Annual Subscription',
    gtmCategory: 'RRP',
    gtmVariant: 'monthly',
    productCategories: ['rrp'],
    accessoryCategories: ['rrp'],
    weight: 0,
  },
  rrpMonthToMonth_Yearly_Bundle: {
    sku: 'rrpMonthToMonth_Yearly_Bundle',
    type: 'subscription',
    price: 139,
    immediateChargePrice: 0,
    description: 'Paid monthly, 1 year term, auto renewal',
    frequency: 'month',
    cartTitle: 'RRP Monthly, Annual Subscription',
    gtmCategory: 'RRP',
    gtmVariant: 'monthly',
    productCategories: ['rrp'],
    accessoryCategories: ['rrp'],
    weight: 0,
    showDisclaimer: true,
  },
  rrpYearToYear_Yearly: {
    sku: 'rrpYearToYear_Yearly',
    type: 'subscription',
    price: 1499,
    immediateChargePrice: 1499,
    description: '1 year term, auto renewal',
    frequency: 'year',
    gtmCategory: 'RRP',
    cartTitle: 'RRP Annual Subscription',
    gtmVariant: 'yearly',
    productCategories: ['rrp'],
    accessoryCategories: ['rrp'],
    weight: 0,
  },
}

const FOCUS_PRODUCTS = {
  focusCertification: {
    sku: 'focusCertification',
    type: 'digitalProduct',
    price: 199,
    immediateChargePrice: 199,
    description: 'Integrated Listening System Training Certification',
    frequency: 'once',
    gtmCategory: 'Training',
    cartTitle: 'Foundational ILS Training',
    productCategories: ['focus'],
    accessoryCategories: [],
    weight: 0,
  },
  focusMonthToMonth_Yearly_Bundle: {
    sku: 'focusMonthToMonth_Yearly_Bundle',
    type: 'subscription',
    price: 139,
    immediateChargePrice: 0,
    description: 'Paid monthly, 1 year term, auto renewal',
    frequency: 'month',
    cartTitle: 'ILS Subscription',
    gtmCategory: 'Focus',
    gtmVariant: 'monthly',
    productCategories: ['focus'],
    accessoryCategories: ['focusAccessory'],
    weight: 0,
    showDisclaimer: true,
  },
  focusMonthToMonth_Yearly: {
    sku: 'focusMonthToMonth_Yearly',
    type: 'subscription',
    price: 139,
    immediateChargePrice: 139,
    description: 'Paid monthly, 1 year term, auto renewal',
    frequency: 'month',
    cartTitle: 'ILS Subscription',
    gtmCategory: 'Focus',
    gtmVariant: 'monthly',
    productCategories: ['focus'],
    accessoryCategories: ['focusAccessory'],
    weight: 0,
  },
  focusYearToYear_Yearly: {
    sku: 'focusYearToYear_Yearly',
    type: 'subscription',
    price: 1499,
    immediateChargePrice: 1499,
    description: '1 year term, auto renewal',
    frequency: 'year',
    gtmCategory: 'Focus',
    cartTitle: 'ILS Subscription',
    gtmVariant: 'bundle',
    productCategories: ['focus'],
    accessoryCategories: ['focusAccessory'],
    weight: 0,
  },
  'FOCUS-STRT': {
    sku: 'FOCUS-STRT',
    type: 'physicalProduct',
    price: 299,
    immediateChargePrice: 299,
    description: '(Includes Hardware Kit and Integration Kit)',
    frequency: 'once',
    gtmCategory: 'Physical',
    cartTitle: 'ILS Starter Pack',
    gtmVariant: 'bundle',
    productCategories: ['focus'],
    accessoryCategories: ['focusAccessory'],
    weight: 11,
  },
}

const VOICE_PRO_PRODUCTS = {
  voiceProCertification: {
    sku: 'voiceProCertification',
    type: 'digitalProduct',
    price: 249,
    immediateChargePrice: 249,
    description: 'VoicePro Training',
    frequency: 'once',
    gtmCategory: 'Training',
    cartTitle: 'VoicePro Training',
    productCategories: ['voice_pro'],
    accessoryCategories: [],
    weight: 0,
  },
}

export const PRODUCTS = {
  ...PROMO_PRODUCTS,
  ...SSP_PRODUCTS,
  ...RRP_PRODUCTS,
  ...FOCUS_PRODUCTS,
  ...VOICE_PRO_PRODUCTS,
}
