/**
 * With Route implementing the layout, we want a component that only serves resource items
 */
import React, { useState, useEffect, useCallback } from 'react'
import moment from 'moment'
import { Favorite, OpenInBrowser, PlayCircleOutline, Search } from '@mui/icons-material'
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import { useNavigate, useParams, useOutletContext, useLocation, Link } from 'react-router-dom'
import Fuse from 'fuse.js'
import { useSelector, useDispatch } from 'react-redux'
import { get } from 'lodash'

import IntroDialog from '../IntroDialog'
import spaceToChars from 'utils/spaceToChars'
import resources from '../../utils/constants/resources'
import { setBookmark } from '../../store/modules/resources'
import FILES from 'utils/constants/files'
import { FilterChip } from 'components/FilterChip'
import CloseIcon from '@mui/icons-material/Close'
import { debounce } from 'utils/debounce'

const INITIAL_TAG_STATES = {
  'Getting Started': false,
  Favourites: false,
  ILS: false,
  SSP: false,
  Assessments: false,
  VoicePro: false,
  Videos: false,
  Manuals: false,
  New: false,
  Delivery: false,
  'Client Education': false,
  Marketing: false,
  Pricing: false,
  'Tech Support': false,
  'ILS Activity': false,
  'Regulating Activity': false,
  Regulation: false,
  'Gross Motor': false,
  'Fine Motor': false,
  'Visual Motor': false,
  'Early Development': false,
  'Provider Toolkit': false,
  Breathing: false,
  Mindfulness: false,
  Movement: false,
  Vocalization: false,
}

// for input search
const OPTIONS = {
  threshold: 0.2,
  shouldSort: true,
  ignoreLocation: true,
  keys: ['productCategory', 'resourceType', 'shortDescription', 'title'],
}

export default function ResourceItems() {
  // hooks
  const { tag } = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  // react state variables
  const [tags, setTags] = useState({ ...INITIAL_TAG_STATES })
  const [showFocusActivityLibraryIntro, setShowFocusActivityLibraryIntro] = useState(false)

  // redux variables
  const currentUserRoles = useSelector((state) => get(state, 'auth.user.roles', []))
  const bookmarks = useSelector((state) => state.resources)

  const onTextChange = (event) => {
    const text = event.target.value
    if (text) {
      setKeywords(text.toLowerCase())
    } else {
      setKeywords()
    }
  }

  const {
    filteredTags,
    chipTags,
    hasSspProducts,
    hasSspCertification,
    hasPaidSspSubscription,
    hasFocusProducts,
    hasFocusCertification,
    hasVoiceProCertification,
    hasAssessmentProducts,
    clientHasFocus,
    hasCompletedFocusCertification,
    hasCompletedSspCertification,
    hasPaidFocusSubscription,
    cardIndex,
    setCardIndex,
    focusRefs,
    keywords,
    setKeywords,
  } = useOutletContext()

  // Step 1: Basic filtering
  const filteredResources = resources
    // Current user Role
    .filter(({ roles }) => currentUserRoles.some((role) => roles.includes(role)))
    // Product Status
    .filter(({ isEnabledByProductsStatus }) =>
      isEnabledByProductsStatus({
        hasSspCertification,
        hasCompletedSspCertification,
        hasPaidSspSubscription,
        hasSspProducts,
        hasFocusCertification,
        hasCompletedFocusCertification,
        hasFocusProducts,
        hasPaidFocusSubscription,
        clientHasFocus,
        hasAssessmentProducts,
        hasVoiceProCertification,
      })
    )
    // Page tabs (General Resources, Provider Toolkit,  Activity Library)
    .filter((resource) => resource.tag === tag)

  // Step 2: Filter by selected Filter tags
  const filterBySelectingTags = filteredResources.filter(({ productCategory, id, uploadDate }) => {
    if (Object.values(tags).every((val) => !val)) {
      // if no tags are selected, show all
      return true
    } else {
      const TAG_EXCLUSIONS = ['New', 'Favourites'] // these are not part of product category filters

      const selectedProductCategories = chipTags
        .filter(
          (chipTag) => !TAG_EXCLUSIONS.includes(chipTag.filterName) && tags[chipTag.filterName]
        )
        .map((chipTag) => chipTag.cardName)

      const doesMatchAllSelectedCategories = selectedProductCategories.every(
        (selectedProductCategory) => productCategory.includes(selectedProductCategory)
      )
      const doesMatchFavouritesTag = tags.Favourites ? get(bookmarks, id, false) : true
      const doesMatchNewTag = tags.New ? moment(uploadDate) > moment().subtract(60, 'days') : true

      return doesMatchAllSelectedCategories && doesMatchFavouritesTag && doesMatchNewTag
    }
  })

  // Step 3: Filter by dynamic search using Fuse
  const fuseEngine = new Fuse(filterBySelectingTags, OPTIONS)
  const filterBySearchingKeywords = keywords
    ? fuseEngine.search(keywords).map(({ item }) => item)
    : filterBySelectingTags

  // Finally sort the resources bookmarks
  if (Object.keys(bookmarks).length > 0) {
    filterBySearchingKeywords.sort((a, b) => {
      if (bookmarks[a.id] && !bookmarks[b.id]) {
        return -1 // a should come before b
      } else if (!bookmarks[a.id] && bookmarks[b.id]) {
        return 1 // b should come before a
      } else {
        return 0 // no change in order
      }
    })
  }

  const onBookmarkClick = (id) => () => {
    const bookmarkState = get(bookmarks, id, false)
    dispatch(setBookmark({ [id]: !bookmarkState }))
  }

  // if we have tags in history.location.search, update chips
  useEffect(() => {
    const filteredTagsArray = filteredTags.map((filteredTag) => filteredTag.filterName)
    const tagsFromURL = location.search
      .replace('?tags=', '')
      .split(',')
      .map((tagURL) => tagURL.replace(/\+/g, ' '))
      .filter((tagURL) => !(tagURL === '' || !INITIAL_TAG_STATES.hasOwnProperty(tagURL)))
    const validatedTags = tagsFromURL.filter((tag) => filteredTagsArray.includes(tag))
    // eslint-disable-next-line no-sequences
    const tagsState = validatedTags.reduce((acc, curr) => ((acc[curr] = true), acc), {})
    let tagsObj =
      tagsFromURL.length === 0 ? INITIAL_TAG_STATES : { ...INITIAL_TAG_STATES, ...tagsState }
    setTags(tagsObj)

    // eslint-disable-next-line
  }, [location.search])

  // On click add this event toGTM
  const handleClick = (event) => {
    if (event.target.textContent) {
      const newTagsObj = {
        ...tags,
        [event.target.textContent]: !tags[event.target.textContent],
      }
      setTags(newTagsObj)
      const searchParamsURL = Object.keys(newTagsObj)
        .filter((key) => newTagsObj[key])
        .join(',')
        .replace(/ /g, '+')
      if (!searchParamsURL) {
        navigate(location.pathname)
      } else {
        navigate({ search: `?tags=${searchParamsURL}` })
      }

      // add toGTM
      if (newTagsObj[event.target.textContent]) {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'filterEvent',
          filterType: 'Resource',
          filterValue: event.target.textContent,
        })
      }
    }
  }

  const showFilterChips = filteredResources.reduce((acc, current) => {
    current.productCategory.forEach((prodCat) => acc.add(prodCat))
    if (moment(current.uploadDate) > moment().subtract(60, 'days')) {
      acc.add('New')
    }
    return acc
  }, new Set(['Favourites']))

  const handleButton = (index) => () => {
    setCardIndex(index)
  }

  // Assign or update the refs array
  useEffect(() => {
    focusRefs.current = focusRefs.current.slice(0, filterBySearchingKeywords.length)
    // eslint-disable-next-line
  }, [filterBySearchingKeywords])

  useEffect(() => {
    const card = focusRefs.current[cardIndex]
    if (card) {
      card.scrollIntoView({ behavior: 'smooth' })
    }
    // eslint-disable-next-line
  }, [])

  const handleClearSearch = () => {
    setKeywords()
  }

  const handleSearch = useCallback((searchQuery) => {
    if (searchQuery) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'searchEvent',
        searchQuery: searchQuery,
      })
    }
  }, [])

  const debouncedSearch = useCallback(debounce(handleSearch, 2000), [handleSearch])

  useEffect(() => {
    if (keywords) {
      debouncedSearch(keywords)
    }
  }, [keywords, debouncedSearch])

  return (
    <Stack>
      <IntroDialog
        open={showFocusActivityLibraryIntro}
        title={'How to use the Activity Library'}
        content={
          <FormHelperText className="my-5 py-5" style={{ fontSize: 14, textAlign: 'center' }}>
            <iframe
              title="account video"
              src={FILES.introVideo.activityLibrary}
              width="100%"
              height="360"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </FormHelperText>
        }
        onClose={() => setShowFocusActivityLibraryIntro(false)}
      />

      {/* Chip Component */}
      <Grid container>
        <Grid container item alignItems="center" xs sm md lg>
          <Paper
            sx={{
              p: 0.25,
              my: 1,
              backgroundColor: '#eef7f3',
              display: 'flex',
              alignItems: 'center',
              width: '100%',
            }}
            elevation={0}
            aria-label="search"
          >
            <InputBase
              startAdornment={
                <IconButton aria-label="search" disabled>
                  <Search fontSize="small" />
                </IconButton>
              }
              endAdornment={
                <IconButton aria-label="close" onClick={handleClearSearch}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              }
              placeholder="Search resources"
              value={keywords || ''}
              inputProps={{ 'aria-label': 'search resources' }}
              onChange={onTextChange}
              fullWidth
              sx={{ paddingTop: '3px' }}
            />
          </Paper>
        </Grid>
        {/* chips go here */}
        <Grid container item xs={12} sm={7} md={7} lg={8} pl={3}>
          <Stack
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
            spacing={1}
            alignItems="center"
          >
            <Typography variant="h6">Filters</Typography>
            <Box className="ml-3">
              {filteredTags.map((tag, index) => {
                return (
                  showFilterChips.has(tag.cardName) && (
                    <FilterChip
                      key={`filter-chip-${index}`}
                      isChecked={tags[tag.filterName]}
                      data-test={`resource_filter_${spaceToChars(tag.filterName, '_')}`}
                      label={tag.filterName}
                      handleOnClick={handleClick}
                    />
                  )
                )
              })}
            </Box>
          </Stack>
        </Grid>
      </Grid>
      <Grid item spacing={2} container alignItems="stretch" mt={2}>
        {filterBySearchingKeywords.map(
          (
            { id, title, shortDescription, productCategory, resourceType, imgSrc, src },
            _cardIndex
          ) => (
            <Grid data-test="resource-grids" key={`grid${id}`} item xs={12} sm={6} md={4} lg={3}>
              <Card
                ref={(el) => (focusRefs.current[_cardIndex] = el)}
                data-test={`resource_card_${spaceToChars(title, '_')}`}
                key={`card${id}`}
                sx={{
                  fontSize: '0.75rem',
                }}
              >
                <div>
                  <CardMedia
                    image={imgSrc}
                    sx={{
                      aspectRatio: '10/3',
                      //   //  the css below fixes pixelation on chrome
                      transform: 'translateZ(0) scale(0.999999)',
                    }}
                  />
                  <CardContent className="pt-3">
                    <Grid container alignItems="center" sx={{ height: '3rem' }}>
                      {productCategory.map((category, index) => {
                        if (!filteredTags.find((tag) => tag.cardName === category)?.displayOnCard) {
                          return <Grid item className="h-5" key={`inner-resource-grid-${index}`} />
                        }
                        return (
                          <Grid item key={`inner-resource-grid-${index}`}>
                            <Chip
                              sx={{
                                marginRight: '0.25rem',
                                marginBottom: '0.25rem',
                                height: 'unset',
                                fontSize: '0.75rem',
                                backgroundColor: filteredTags.find(
                                  (tag) => tag.cardName === category
                                )?.chipColor,
                              }}
                              data-test={`resource-card-${spaceToChars(category, '-')}-filter`}
                              label={category}
                              size="small"
                            />
                          </Grid>
                        )
                      })}
                    </Grid>
                    <Typography
                      variant="body1"
                      color="gray"
                      sx={{
                        height: '3.25rem',
                        fontSize: '1.3rem',
                        lineHeight: '1.3rem',
                        margin: '0.25rem 0 1.25rem 0',
                        fontWeight: 500,
                      }}
                      gutterBottom
                      data-test="resource-title"
                    >
                      {title}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="gray"
                      sx={{
                        height: '6rem', // this will fit 4 lines of text
                      }}
                    >
                      {shortDescription}
                    </Typography>
                  </CardContent>
                  <Divider />
                  <CardActions>
                    {[
                      'secure-storage-file',
                      'link',
                      'focusActivity',
                      'regulatingActivity',
                      'titration-tool',
                    ].includes(resourceType) && (
                      <Button
                        color="text"
                        sx={{
                          mr: 'auto',
                          marginLeft: '0.5rem',
                        }}
                        startIcon={<OpenInBrowser />}
                        component={Link}
                        to={`${location.pathname}/${encodeURIComponent(
                          title
                            .trim()
                            .replace(/ /g, '-')
                            .replace(/\//g, '-')
                        )}/${id}${location.search}`}
                        onClick={handleButton(_cardIndex)}
                      >
                        <Typography variant="button">Open</Typography>
                      </Button>
                    )}
                    {resourceType === 'externalLink' && (
                      <Button
                        color="text"
                        sx={{
                          mr: 'auto',
                          marginLeft: '0.5rem',
                        }}
                        startIcon={<OpenInBrowser />}
                        onClick={() => {
                          window.open(src, '_blank')
                        }}
                      >
                        <Typography variant="button">Open</Typography>
                      </Button>
                    )}
                    {resourceType === 'videos' && (
                      <Button
                        color="text"
                        sx={{
                          mr: 'auto',
                          marginLeft: '0.5rem',
                        }}
                        component={Link}
                        to={`${location.pathname}/${title
                          .trim()
                          .replace(/ /g, '-')
                          .replace(/\//g, '-')}/${id}${location.search}`}
                        startIcon={<PlayCircleOutline />}
                        onClick={handleButton(_cardIndex)}
                      >
                        <Typography variant="button">Watch</Typography>
                      </Button>
                    )}
                    <Tooltip title="Add to Favourites">
                      <IconButton
                        aria-label="add to favorites"
                        value="favorite"
                        onClick={onBookmarkClick(id)}
                        size="large"
                        data-test="add-bookmark-button"
                      >
                        <Favorite color={get(bookmarks, id, false) ? 'favorite' : 'disabled'} />
                      </IconButton>
                    </Tooltip>
                  </CardActions>
                </div>
              </Card>
            </Grid>
          )
        )}
      </Grid>
    </Stack>
  )
}
