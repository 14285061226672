import React from 'react'
import { useSelector } from 'react-redux'
import { get } from 'lodash'

import ROLES from 'utils/constants/roles'

import { Grid, Typography } from 'components'
import makeStyles from '@mui/styles/makeStyles'

import MainLayout from 'components/containers/main/Main'
import { isRrpCertified } from 'utils/permissions/permissionsLogic'
import ProductCard from './ProductCard'
import PathwayCards from './PathwayCards'
import { showPathwayProductCards } from '../../utils/permissions/permissionsLogic'

const useStyles = makeStyles({
  grid: {
    justifyContent: 'center',
    padding: 20,
    paddingTop: 15,
  },
  sectionTitle: {
    fontSize: '25px',
    fontWeight: 600,
    textAlign: 'center',
    margin: '40px 0',
  },
})

export default function Products() {
  const classes = useStyles()
  const data = useSelector((state) => state.seats.products)
  const organization = useSelector((state) => state.organization)
  const isClient = useSelector((state) => get(state, 'auth.user.roles', []).includes(ROLES.CLIENT))
  const authUser = useSelector((state) => state?.auth?.user)
  const rrpCertified = isRrpCertified({ authUser })
  const showPathwayCards = showPathwayProductCards({ authUser, organization })

  const filteredData = data
    ?.filter((item) => item?.category !== 'assessment')
    .filter((item) => item?.category !== 'rrp' || (item?.category === 'rrp' && rrpCertified))
    .filter(Boolean)

  return (
    <MainLayout title="Programs" className={classes.grid}>
      <Grid container className="p-5">
        {showPathwayCards && (
          <>
            <Grid item xs={12} md={12} lg={12}>
              <Typography className={classes.sectionTitle}>SSP Pathways</Typography>
            </Grid>
            <PathwayCards />
            <Grid item xs={12} md={12} lg={12}>
              <Typography className={classes.sectionTitle}>Playlists</Typography>
            </Grid>
          </>
        )}
        {filteredData
          ?.sort((a, b) => a.order - b.order)
          .map(({ name, description, metadata, id }, index) => (
            <ProductCard
              name={name}
              id={id}
              description={description}
              metadata={metadata}
              index={index}
            />
          ))}
        {!filteredData.length && isClient && (
          <div>
            <Typography gutterBottom variant="body2" className="py-2">
              Sorry but you currently don't have access to any available programs.
            </Typography>
            <Typography gutterBottom variant="body2" className="py-2">
              Please contact your Provider for more details.
            </Typography>
          </div>
        )}
        {!filteredData.length && !isClient && (
          <div>
            <Typography gutterBottom variant="body2" className="py-2">
              Sorry but you currently don't have access to any available programs. You might not
              have an active subscription.
            </Typography>
            <Typography gutterBottom variant="body2" className="py-2">
              Please contact Unyte Health support for more details.
            </Typography>
          </div>
        )}
      </Grid>
    </MainLayout>
  )
}
