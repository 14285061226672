import React from 'react'
import { useTheme } from '@mui/material/styles'
import ProductCard from './ProductCard'

export default function PathwayCards() {
  const theme = useTheme()

  const connectMetadata = {
    borderColor: theme.palette.yellow.dark,
    pictureUrl: '/images/paths/ConnectCard.png',
  }
  const connectDescription =
    'Full spectrum, unfiltered music. ' +
    'SSP Connect can be offered as an introduction to the SSP to ' +
    'assess sensitivity to listening, and enhance overall readiness ' +
    'for SSP Core. These 5 hours of music can be listened to in any order.'

  const coreMetadata = {
    borderColor: theme.palette.pink.dark,
    pictureUrl: '/images/paths/CoreCard.png',
  }

  const coreDescription =
    'Progressive, dynamically filtered music. ' +
    'This pathway is the strongest and potentially most therapeutic of the ' +
    'three pathways. It is important to listen in order until the ' +
    'program is complete.'

  const balanceMetadata = {
    borderColor: theme.palette.mint.dark,
    pictureUrl: '/images/paths/BalanceCard.png',
  }

  const balanceDescription =
    'Lightly filtered music. ' +
    'SSP Balance can be used to continue or extend benefits from ' +
    'SSP Core. This pathway can be repeated and listened to in any order.'

  return (
    <>
      <ProductCard
        name={'Connect'}
        id={'connect'}
        description={connectDescription}
        metadata={connectMetadata}
        pathwayCard
      />
      <ProductCard
        name={'Core'}
        id={'core'}
        description={coreDescription}
        metadata={coreMetadata}
        pathwayCard
      />
      <ProductCard
        name={'Balance'}
        id={'balance'}
        description={balanceDescription}
        metadata={balanceMetadata}
        pathwayCard
      />
    </>
  )
}
