import React, { useEffect, useState } from 'react'
import MainLayout from 'components/containers/main/Main'
import { useNavigate } from 'react-router'
import { Typography, Card, CardContent, Box, TextField, Button } from 'components'
import { gql, useMutation } from '@apollo/client'
import { useForm, Controller } from 'react-hook-form'

const CREATE_REFERRAL = gql`
  mutation createReferral($referral: CreateReferralInput!) {
    createReferral(referral: $referral) {
      firstName
      lastName
      email
    }
  }
`

const formFields = [
  { key: 'firstName', label: 'First Name' },
  { key: 'lastName', label: 'Last Name' },
  { key: 'email', label: 'Email' },
]

export default function ReferralProgram() {
  const navigate = useNavigate()
  const [createReferral, { loading, error }] = useMutation(CREATE_REFERRAL)
  const { control, handleSubmit, reset } = useForm({
    defaultValues: { firstName: '', lastName: '', email: '' },
  })
  const [isSubmitted, setIsSubmitted] = useState(false)

  useEffect(() => {
    if (process.env.REACT_APP_STAGE === 'prod') navigate('/')
    // eslint-disable-next-line
  }, [])

  const onSubmit = async (formData) => {
    try {
      await createReferral({ variables: { referral: formData } })
      reset()
      setIsSubmitted(true)
    } catch (err) {
      console.error('Submission Error:', err.message)
    }
  }

  return (
    <MainLayout title="Unyte Provider Referral Program: Give a Month, Get a Month!">
      <Card elevation={0} sx={{ padding: 1 }}>
        <CardContent>
          {isSubmitted && (
            <Box sx={{ maxWidth: '60%', p: 2 }}>
              <Typography fontWeight={500} mb={2}>
                Thank you for your referral!
              </Typography>
              <Typography mb={2}>
                We appreciate you sharing Unyte programs with your network. If the individual you
                referred does not have an active subscription, we'll send them an email with details
                about subscribing. Once they activate their subscription, we'll notify both of you
                via email and apply your free month credit.
              </Typography>
              <Typography mb={2}>Is there someone else you want to refer?</Typography>
              <Button
                onClick={() => setIsSubmitted(false)}
                aria-label="Make another referral"
                variant="outlined"
                color="primary"
              >
                <Typography variant="button">Make another referral</Typography>
              </Button>
            </Box>
          )}
          {!isSubmitted && (
            <CardContent>
              <Typography sx={{ mb: 2 }}>
                We believe that the best recommendations come from trusted colleagues, and want to
                reward you for spreading the word. Our referral program makes it easy for you to
                share the benefits of our programs with others and earn a reward.
              </Typography>
              <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                sx={{
                  maxWidth: 400,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: 2,
                }}
              >
                {formFields.map(({ key, label }) => (
                  <Controller
                    key={key}
                    name={key}
                    control={control}
                    render={({ field }) => <TextField {...field} label={label} type={key} />}
                  />
                ))}
                <Button type="submit" variant="contained" color="primary" disabled={loading}>
                  {loading ? 'Submitting...' : 'Submit'}
                </Button>
                {error && (
                  <Typography color="error">
                    Error: {error.message?.replace(/Validation error:/g, '').trim()}
                  </Typography>
                )}
              </Box>
            </CardContent>
          )}
        </CardContent>
      </Card>
    </MainLayout>
  )
}
