import React from 'react'
import makeStyles from '@mui/styles/makeStyles'

import LINKS from 'utils/constants/links'
import { Collapse, Card, Button, Grid, Typography, CardContent, CardMedia } from 'components'

const useStyles = makeStyles({
  title: {
    fontWeight: 'bold',
  },
  text: {
    whiteSpace: 'pre-wrap',
    fontSize: '14px',
    color: '#616161',
  },
  programDescription: {
    minHeight: '200px',
  },
  pathwayDescription: {
    height: '140px',
  },
  expanded: {
    color: '#000',
    transform: ({ expanded }) => (expanded ? 'rotate(180deg)' : 'rotate(0)'),
    height: '100%',
  },
  emptyExpansion: {
    height: '38px',
  },
  cardHeight: {
    height: '100%',
    width: '100%',
  },
  googleLogo: {
    height: 36,
    width: 107,
  },
  appleLogo: {
    height: 36,
    width: 119,
  },
  labelText: {
    whiteSpace: 'pre-wrap',
    fontSize: '12px',
    color: '#616161',
    textAlign: 'center',
  },
  divider: {
    height: '1px',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    border: 'none',
    margin: 0,
    width: '37%',
  },
})

export default function ProductCard({ description, metadata, name, index, pathwayCard }) {
  const classes = useStyles()
  const [expanded, setExpanded] = React.useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const borderSX = {
    borderTop: 10,
    borderBottom: 10,
    borderColor: metadata.borderColor,
  }

  return (
    <Grid item xs={12} md={6} lg={4} className={`${classes.card} p-2`} key={`product-${index}`}>
      <Card justify="center" className={classes.cardHeight}>
        <CardMedia
          sx={metadata?.borderColor ? borderSX : null}
          height="200"
          component="img"
          src={metadata.pictureUrl}
        />
        <CardContent style={{ paddingBottom: '0px' }}>
          <div className={pathwayCard ? classes.pathwayDescription : classes.programDescription}>
            <Typography className={`${classes.title} mt-2 mb-3`}>{name}</Typography>
            <Typography className={classes.text} paragraph>
              {description}
            </Typography>
            {!pathwayCard &&
              (metadata.expandedContent ? (
                <>
                  <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <Typography className={classes.text} style={{ color: '#616161' }} paragraph>
                      {metadata.expandedContent}
                    </Typography>
                  </Collapse>
                  <Grid container direction="row" justifyContent="space-around" alignItems="center">
                    <Grid item className={classes.divider} />
                    <Grid item>
                      <Button onClick={handleExpandClick} aria-label="show more">
                        <Typography variant="button">{expanded ? 'Less' : 'More'}</Typography>
                      </Button>
                    </Grid>
                    <Grid item className={classes.divider} />
                  </Grid>
                </>
              ) : (
                <div className={classes.emptyExpansion} />
              ))}
          </div>
          {!pathwayCard && (
            <div className="mt-1">
              <Typography className={classes.labelText}>
                This program requires the Unyte Health app.
              </Typography>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                className="py-3"
              >
                <Grid item>
                  <a target="_blank" rel="noopener noreferrer" href={LINKS.download.playStore.href}>
                    <img
                      alt="Android app"
                      className={classes.googleLogo}
                      src={LINKS.download.playStore.imgSrc}
                    />
                  </a>
                </Grid>
                <Grid item>
                  <a target="_blank" rel="noopener noreferrer" href={LINKS.download.appStore.href}>
                    <img
                      alt="App Store"
                      className={classes.appleLogo}
                      src={LINKS.download.appStore.imgSrc}
                    />
                  </a>
                </Grid>
              </Grid>
            </div>
          )}
        </CardContent>
      </Card>
    </Grid>
  )
}
